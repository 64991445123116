@font-face {
    font-family: Evermore;
    src: url(../../cdn/fuentes/Evermore.ttf);
}


header{
	width: 100%;
	height: 90px;
	background-color: #18181b;
	border-top: 5px solid #ffde59;
}
.interior{
	max-width: 100%;
	padding: 0 10px;
	margin: auto;
}
.logo{
	float: left;
	display: flex;
	align-items: center;
	padding: 15px 20px 0;
	
}

.logo img{
	height: 60px;
	border-radius: 50%;
}
.logo img:hover{
	
	/*transition: .3s linear;
	transform: scale(1.1);*/
	
}
.navegacion{
	float: right;
	display: flex;
	align-items: center;
	min-height: 90px;
	z-index: 1000;
}
.navegacion ul{
	list-style: none;
	padding: 0 10px;
}
.navegacion ul li{
	display: inline-block;
	position: relative;
	transition: .3s linear;
	z-index: 1000;
}
.navegacion ul li:hover{
	
	transition: .3s linear;
	color: rgb(255, 255, 255);
	transform: translateY(-3px);
}
.navegacion ul li button{
	color: #ffffff;
	background-color: transparent;
	border: 0;
	text-align: center;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: bold;
	padding: 12px 20px;
	transition: .3s linear;
	text-decoration: none;
	cursor: pointer;

}


@media only screen and (max-width: 768px) {

	.navegacion ul{
		list-style: none;
		justify-content: center;
		align-items: center;
		display: flex;
		text-align: center;
		margin-right: 15px;
	
	}


    
}