.footer-todo {
    background-color: #18181b;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-top: 50px;

}
