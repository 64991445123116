.Info .code{

    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    color: #fff;

}

.Info .codigos {
    background-color: rgb(49, 49, 49);
    border-bottom: 6px solid #757474;
    padding: 20px;
    width: 300px;
    height: 58px;
    margin: 10px;
    border-radius: 10px;
    
}
.Info .codigos .title {
    font-size: 20px;
}
.Info .codigos .descripcion {
    font-size: 15px;
    opacity: 80%;
    padding-left: 5px;
}

.Info .codigos i {

    padding: 10px;
    cursor: pointer;
    background-color: #757474c6;
    color: #fff;
    border-radius: 50%;
    border: 1.5px solid transparent;
    

}

.Info .codigos i:hover {
    background-color: #5d5d5bfc;
    border-radius: 50%;
}
.Info .codigos i:active {

    background-color: #ffde5981;
    border: 1.5px solid #ffde59;
    border-radius: 50%;
    
}

@media only screen and (max-width: 768px) {
    .Info .codes{

        display: block;
        zoom: 90%;
        
    
    }
    .Info .code {
        justify-content: center;
        display: flex;
        align-items: center;
        flex-wrap: wrap
    }
}